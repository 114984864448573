/* eslint-disable no-console */
import Sentry, { initSentry as initLiftoffSentry } from "@liftoff/sentry/react";
import _ from "lodash";
import { gzip } from "pako/lib/deflate";
import React from "react";
import StackTrace from "stacktrace-js";

export const production = process.env.NODE_ENV === "production";
export const development = !production;
// staging is for running production builds on staging environments
export const staging = !!process.env.STAGING_ENV;

console.log("Production", production);
if (staging) {
  console.log("Staging", staging);
}

// The ploy version.
export const appVersion = process.env.APP_VERSION;

console.debug("App version", appVersion);

// deployedVersion can be falsy if ploy server is not reachable
export function isOutdatedAppVersion(deployedVersion) {
  const outdated =
    appVersion && deployedVersion && appVersion !== deployedVersion;
  if (outdated) console.debug("New app version available", deployedVersion);
  return outdated;
}

export function capitalizeFirst(str) {
  if (!str || str.length === 0) return str;
  return str[0].toUpperCase() + str.substring(1);
}

export const humanizeSnakeCase = (string) => {
  if (!string) return string;
  return string.split("_").map(_.capitalize).join(" ");
};

export const humanizeCamelCase = (s) => {
  return _.capitalize(s.replace(/([A-Z])/g, " $1"));
};

export function isNullOrUndefined(val) {
  return _.isUndefined(val) || _.isNull(val);
}

export function isDefined(val) {
  return !(_.isUndefined(val) || _.isNull(val));
}

export function nullifyEmptyArray(arr) {
  return _.isEmpty(arr) ? null : arr;
}

export function checkResponse(response) {
  if (!response.ok) {
    if (response.status === 401) {
      window.location = "/signout";
      // TODO(nathan): This promise remains unresolved until execution ends from navigating to /signout.
      // This is because the fetch requests that use checkResponse do not catch promise rejections
      // and would report to /js_error, and there is no common 'empty' state to resolve to.
      return new Promise(_.noop);
    }
    if (response.status === 400 || response.status === 403) {
      // NOTE(stefan): We return unauthorized requests in case it contains a JSON payload.
      return response;
    }
    return response.text().then(() => {
      throw new Error(
        `Request unsuccessful: ${response.url} ${response.status} ${response.statusText}`
      );
    });
  }
  return undefined;
}

export function responseToText(response) {
  return checkResponse(response) || response.text();
}

export function responseToJson(response) {
  return checkResponse(response) || response.json();
}

export function fetchJson(url, options) {
  return fetch(url, {
    ...options,
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
  }).then(responseToJson);
}

export function createFormBody(obj) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
}

export function postForm(url, obj) {
  return fetch(url, {
    method: "POST",
    redirect: "follow",
    body: createFormBody(obj),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
}

/**
 * Sends a compressed file as form data to a specified url
 * @param {string} url - required target url
 * @param {File} file
 * @param {Object} options - optional param for configurations
 * - urlMethod = string, HTTP method type for target url
 * - handleError, handleUpdate, handleSuccess = callback functions
 * - additionalFormFields = object, additional form params
 * - fileFieldName = string, for file input field name
 */
export function uploadSingleFileForm(url, file, options = {}) {
  const fileReader = new FileReader();
  const {
    urlMethod = "POST",
    handleError = _.noop,
    handleUpdate = _.noop,
    handleSuccess = _.noop,
    additionalFormFields = {},
    fileFieldName = "file",
  } = options;
  fileReader.onprogress = handleUpdate;
  fileReader.onload = (event) => {
    const compressedFile = new Blob([
      gzip(event.currentTarget.result, { level: 1 }),
    ]);
    const fd = new FormData();
    Object.entries(additionalFormFields).forEach(([key, value]) => {
      fd.append(key, value.toString());
    });
    fd.append(fileFieldName, compressedFile, `${file.name}.gz`);
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", handleUpdate, false);
    xhr.addEventListener("load", handleSuccess, false);
    xhr.addEventListener("error", handleError, false);
    xhr.open(urlMethod, url, true);
    xhr.send(fd);
  };
  fileReader.readAsArrayBuffer(file);
}

/*
 * Reads a file from a File object.
 * @param {File} file
 * @returns {Promise} A Promise of the results of `FileReader.readAsArrayBuffer`
 */
export function readFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => resolve(event.currentTarget.result);
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });
}

/**
 * Packs arr FIFO evenly into n arrays/buckets based on given value. Buckets are filled based on the lowest
 * respective running total and then left to right.
 * @param {Object[]} arr - List of elements to run getValue() on.
 * @param {number} size - Number of buckets to fill.
 * @param {Function} getValue - Ran on each element of arr. Must return a numerical value.
 * @example
 * packBy([4, 3, 2, 1], 2) // returns [[4, 1], [3, 2]]
 * packBy([4, 3, 2, 1], 3) // returns [[4], [3], [2, 1]]
 * @returns {Object[][]}
 */
export const packBy = (arr, size = 2, getValue = (el) => el) => {
  const runningTotals = Array(size).fill(0);
  const buckets = _.groupBy(arr, (el) => {
    const minIndex = runningTotals.indexOf(_.min(runningTotals));
    runningTotals[minIndex] += getValue(el);
    return minIndex;
  });

  return Object.values(buckets);
};

export function post(url, body) {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json;charset=UTF-8" },
    credentials: "include",
  });
}

// handleError argument signature must match that of window.onerror
function handleError(message, url, lineNumber, columnNumber, error, context) {
  if (!production) {
    console.error(message, { url, lineNumber, columnNumber, error, context });
  }
  if (url?.startsWith("chrome-extension")) return;
  const msgText =
    typeof message === "string" ? message : message?.message || "Unknown error";
  if (msgText === "ResizeObserver loop limit exceeded") return;
  if (msgText?.match(/'observe' on 'IntersectionObserver'/)) return;
  if (msgText?.match(/Request unsuccessful: .* 5\d\d Server Error/)) return;
  if (msgText?.match(/^Script error\.$/)) return;
  if (
    msgText === "ResizeObserver loop completed with undelivered notifications."
  )
    return;
  if (msgText?.match(/TypeError: cancelled/)) return;

  const location = window.location.href;

  const postError = (error) =>
    post("/js_error", {
      version: appVersion,
      message: msgText,
      url,
      "line-number": lineNumber,
      "column-number": columnNumber,
      error,
      location,
      context,
    }).then((response) => {
      if (!response.ok) {
        throw new Error(
          `Post request to /js_error was not ok, got ${response.status}`
        );
      }
      return response;
    });

  if (error instanceof Error) {
    const title = `${error.name}: ${error.message}`;
    StackTrace.fromError(error)
      .then(
        (stackFrames) =>
          postError({
            stack: [
              title,
              ...stackFrames.map((sf) => `    at ${sf.toString()}`),
            ].join("\n"),
          }),
        (e) =>
          postError({
            stack: [
              title,
              "Failed to get stacktrace due to:",
              e?.stack || e?.message || JSON.stringify(e),
            ].join("\n"),
          })
      )
      .catch((e) => {
        console.error(
          "Failed to report the error:",
          message,
          url,
          lineNumber,
          columnNumber,
          error,
          context,
          "due to:",
          e
        );
      });
  } else {
    postError(JSON.stringify(error));
  }
}

export function reportError(message, error, context) {
  Sentry.captureException(error, (scope) => {
    scope.setExtra("message", message);
    if (!context) return;
    Object.keys(context).forEach((k) => {
      const val = context[k];
      switch (k) {
        case "redux":
          // Don't include the Redux store, it will exceed Sentry size limitations.
          scope.setContext("reduxAction", val?.action);
          break;
        case "team":
          // Tags are searchable in Sentry.
          scope.setTag(k, val);
          break;
        default:
          scope.setContext(k, val);
      }
    });
  });
  handleError(message, undefined, undefined, undefined, error, context);
}

export function setupWhyDidYouRender() {
  if (process.env.NODE_ENV === "development" && process.env.WDYR) {
    // eslint-disable-next-line global-require
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, { trackAllPureComponents: true });
  }
}

export function setupErrorHandler() {
  window.addEventListener("error", handleError);
  window.addEventListener("unhandledrejection", (event) => {
    if (`${event.reason}` === "TypeError: Failed to fetch") {
      return;
    }
    reportError(`Uncaught (in promise) ${event.reason}`, event.reason);
  });
}

export function preventDefault(f) {
  return _.isUndefined(f)
    ? undefined
    : (e) => {
        e.preventDefault();
        f(e);
      };
}

export function stopPropagation(f) {
  return _.isUndefined(f)
    ? undefined
    : (e) => {
        e.stopPropagation();
        f(e);
      };
}

export function just(f) {
  return _.isUndefined(f)
    ? undefined
    : (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        f(e);
      };
}

export function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export function toDegrees(radians) {
  return (radians * 180) / Math.PI;
}

export function defaultMenuItemDisableFunction(menuItem) {
  return {
    ...menuItem,
    disabled: menuItem.disabled || (({ data = {} } = {}) => _.isEmpty(data)),
  };
}

export function getSpendCapVals({ daily_spend_limit, daily_revenue_limit }) {
  return daily_spend_limit
    ? { capValue: daily_spend_limit, capType: "spend" }
    : { capValue: daily_revenue_limit, capType: "revenue" };
}

export const usesWeeklyRevenueCap = (entity) => {
  const { spend_cap_type } = entity;
  return spend_cap_type === "pacer_weekly";
};

// Translates weekly spend cap from the UI into the daily value for the database
// if appropriate for the cap type.
export const updateSpendCapForDB = (spendCap, spendCapType) => {
  if (spendCapType === "pacer_weekly") {
    return spendCap / 7;
  }
  return spendCap;
};

// Translates the daily spend cap stored in the database into a weekly spend cap
// for campaigns on that timeframe.
export const updateSpendCapForUI = (spendCap, spendCapType) => {
  if (spendCapType === "pacer_weekly") {
    return spendCap * 7;
  }
  return spendCap;
};

export function metricValue(metric) {
  if (_.isPlainObject(metric)) {
    return metric.value;
  }
  return metric;
}

export function valueOrNothing(value, formatter, nothing = "") {
  return value ? formatter(value) : nothing;
}

export function newTab(event) {
  return event.metaKey || event.ctrlKey;
}

export function goalKeyToMetricName(goalType) {
  const goalMetricMap = {
    nrm: "margin",
    roas_7d: "c7d_roas",
    roas_30d: "c30d_roas",
  };
  // eslint-disable-next-line no-prototype-builtins
  return goalMetricMap.hasOwnProperty(goalType)
    ? goalMetricMap[goalType]
    : goalType;
}

export function getScheduledChangesClass(data, fieldName) {
  if (
    fieldName &&
    (data.scheduled_changes || []).some((c) => c.change_name === fieldName)
  ) {
    return "show-scheduled-changes";
  }
  return "";
}

export function sortData(data, sortedData, column, descending) {
  if (isNullOrUndefined(column) || !data) {
    return data;
  }
  if (descending) {
    // eslint-disable-next-line no-param-reassign
    sortedData = Array.from(sortedData).reverse();
  }
  const { name, dataFn = (a) => a?.[name] } = column;
  // eslint-disable-next-line no-param-reassign
  sortedData = _.sortBy(sortedData, dataFn);
  if (descending) {
    sortedData.reverse();
  }
  return sortedData;
}

export function downloadBlob(blob, filename) {
  const el = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  el.href = url;
  el.style.display = "none";
  el.download = filename;
  document.body.appendChild(el);
  el.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(el);
  });
}

/**
 * Downloads a given text as file with a given filename.
 */
export function download(filename, text) {
  const blob = new Blob([text], {
    type: "text/plain;charset:utf-8",
  });
  downloadBlob(blob, filename);
}

export function isPromise(obj) {
  return Promise.resolve(obj) === obj;
}

/**
 * Get current window's query parameters.
 */
export function getQueryParams() {
  return new URLSearchParams(window.location.search.substr(1));
}

export function containsIgnoreCase(str, substr) {
  return (
    typeof str === "string" &&
    typeof substr === "string" &&
    str.toLowerCase().includes(substr.toLowerCase())
  );
}

export function equalsIgnoreCase(str, str2) {
  return (
    typeof str === "string" &&
    typeof str2 === "string" &&
    str.toLowerCase() === str2.toLowerCase()
  );
}

export function formatUsername(user) {
  const { first_name, last_name } = user || {};
  return first_name || last_name
    ? `${first_name || ""} ${last_name[0] || ""}.`
    : "";
}

export function sanitizeInputText(text) {
  return text
    .split(/[\n,]/)
    .filter((inputText) => isDefined(inputText))
    .map((inputText) => inputText.trim())
    .filter((inputText) => inputText.length !== 0);
}

export function refreshPage() {
  window.location.reload(true);
}

export const withMaxElements = (
  arr,
  max,
  ellipsis = "...",
  getValue = _.identity
) => {
  if (arr.length > max) {
    return [
      ..._.take(arr, max - 2).map(getValue),
      ellipsis,
      getValue(_.last(arr), max),
    ];
  }
  return arr.map(getValue);
};

export const isValidAppId = (text) => {
  return (
    /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/.test(text) ||
    /^\d{9,10}$/.test(text) ||
    // For weird stuff like "GBWhatsAppAPK"
    /^[A-Za-z]*$/.test(text)
  );
};

// Escapes literal string to be a valid regexp search string
// Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
export function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const fetchUser = () =>
  fetch("/api/users/v1/me")
    .then((resp) => {
      const pathName = window.location.pathname.toUpperCase();
      const unauthorizedPathName = "/UNAUTHORIZED";
      if (resp.status === 403 && pathName !== unauthorizedPathName) {
        window.location = unauthorizedPathName;
      }
      return resp.json();
    })
    // FIXME(jphung) the only page that should be erroring is the password reset
    // page, remove this once the password reset page has a more permanent fix
    .catch(() => undefined);

export const fetchSiteConfig = (customer = true) => {
  return fetchJson(`/api/v2/analytics/site_config?customer=${customer}`);
};

export const PROD_ALLOW_REGEX = new RegExp("^https://app.liftoff.io/.*$");

// NOTE(ryoung) there is some repetition in setup here and in
// skipper/client/assets/js/main.js. Given the fairly disparate JS environments
// these two code blocks should be kept in sync manually.
// Note: ignoreErrors will not be identical between skipper and dasher as these
// are project specific.
export async function initSentry(dsn, userPromise = fetchUser()) {
  initLiftoffSentry(
    {
      dsn,
      ignoreErrors: [
        "TypeError: Failed to fetch",
        "TypeError: NetworkError when attempting to fetch resource.",
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.",
      ],
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 0.5,
      allowUrls: [PROD_ALLOW_REGEX],
    },
    { getUser: userPromise }
  );
}

export async function sentryCaptureException(error, extraScope = {}) {
  Sentry.withScope((scope) => {
    Object.keys(extraScope).forEach((k) => {
      scope.setExtra(k, extraScope[k]);
    });
    Sentry.captureException(error);
  });
}

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

// Default filter logic used by AntD select.
export const filterOptions = (input, option) =>
  option.props.children?.toLowerCase?.().indexOf(input.toLowerCase()) >= 0;

export async function fetchVersion() {
  /* global __webpack_public_path__:writable */
  return (await fetch(`${__webpack_public_path__}version`)).text();
}

export const isEmail = (email) => {
  // https://stackoverflow.com/a/26272713
  // https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    email
  );
};
