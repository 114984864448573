import * as Sentry from "@sentry/browser";
import { BrowserOptions } from "@sentry/browser";

export const isDev = process.env.NODE_ENV === "development";

export const mergeOptions = (config: BrowserOptions): BrowserOptions => {
  // If there is already a Sentry session then reuse the existing integrations.
  const client = Sentry.getCurrentHub().getClient();
  const integrations = client
    ? []
    : [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [/^https:\/\/[^/]*.liftoff.io/],
        }),
        new Sentry.Replay({
          blockAllMedia: true,
        }),
      ];

  return {
    environment: process.env.SENTRY_ENVIRONMENT ?? process.env.NODE_ENV,
    release: process.env.APP_VERSION,
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    integrations,
    tracesSampler: (samplingContext) => {
      if (samplingContext.transactionContext.op === "pageload") {
        return 0.1;
      }
      return 0.05; // 5% of redirect transactions
    },
    // NOTE: ALI we are ignoring these errors to reduce noise
    // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "ResizeObserver loop limit exceeded",
    ],
    ...config,
  };
};

if (window) {
  // Capture unhandled promise rejections
  window.addEventListener("unhandledrejection", (event) => {
    Sentry.captureException(event.reason);
  });

  // Capture global errors
  window.onerror = (message, _source, _lineno, _colno, error) =>
    Sentry.captureException(error || new Error(message as string));
}

export type InitSentryOptions = Partial<{
  disabled: boolean;
  getUser: UserGetter | null;
}>;

export type User = {
  email: string;
};

export type UserGetter = string | Promise<User>;

const fetchUser = async (url: string): Promise<User | undefined> => {
  const resp = await fetch(url);
  if (resp.status === 401) return undefined;
  const contentType = resp.headers.get("Content-Type");
  if (resp.ok && contentType?.startsWith("application/json")) {
    return (await resp.json()) as User;
  }
  throw new Error(`User info fetch response: ${resp.status} ${contentType}`);
};

const isUserPromise = (getUser: UserGetter): getUser is Promise<User> =>
  typeof getUser === "object" && "then" in getUser;

const resolveUser = async (getUser: UserGetter): Promise<User | undefined> => {
  try {
    return await (isUserPromise(getUser) ? getUser : fetchUser(getUser));
  } catch (err) {
    Sentry.captureException(err);
  }
};

export async function configureUserScope(
  sentry: typeof Sentry,
  getUser?: UserGetter | null
): Promise<void> {
  if (getUser === null) return;
  let getter = getUser;
  if (getter === undefined) getter = "/user_info";
  (async () => {
    const user = await resolveUser(getter);
    if (user?.email) {
      sentry.configureScope((scope) => {
        scope.setUser({ email: user.email });
      });
    }
  })();
}
