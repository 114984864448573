import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const icons = {
  AD_SIZE_BANNER: "\ue916",
  AD_SIZE_HALF_INTERSTITIAL: "\ue917",
  AD_SIZE_INTERSTITIAL: "\ue918",
  AD_SIZE_NATIVE: "\ue91a",
  AD_SIZE_VIDEO: "\ue919",
  AI_SPARKLE: "\ue96d",
  ANDROID: "\ue925",
  APP: "\ue963",
  ARCHIVE: "\ue94c",
  ARR_CHEVRON_DOWN: "\ue913",
  ARR_CHEVRON_LEFT: "\ue91c",
  ARR_CHEVRON_RIGHT: "\ue91b",
  ARR_CHEVRON_UP: "\ue914",
  ARR_COLLAPSE_RIGHT: "\ue93b",
  ARR_DOWN: "\ue900",
  ARR_DROPDOWN: "\ue901",
  ARR_RIGHT: "\ue902",
  ARR_UP: "\ue903",
  BADGE: "\ue955",
  BROWSE: "\ue944",
  CALENDAR: "\ue904",
  CAT: "\ue965",
  CHART: "\ue932",
  CHECKMARK_CIRCLE: "\ue929",
  CHECKMARK_ROUND: "\ue928",
  CHECKMARK_SOLID: "\ue927",
  CHECKMARK: "\ue905",
  CIRCLE_LINE: "\ue961",
  CLEAR: "\ue957",
  CLOCK: "\ue91e",
  CLONE: "\ue943",
  CLOSER: "\ue910",
  CODE: "\ue94d",
  DELETE_X: "\ue915",
  DOCUMENT: "\ue906",
  DOWNLOAD: "\ue907",
  EMAIL: "\ue93d",
  EXCLAIM_CIRCLE: "\ue92b",
  EXCLAIM_SOLID: "\ue92a",
  EXCLAIM: "\ue92c",
  FLAG_CIRCLE: "\ue942",
  FLAG: "\ue941",
  FOLDER_SOLID: "\ue94f",
  FOLDER: "\ue945",
  FOLDERS: "\ue94e",
  GRAPH: "\ue924",
  GRAPHIC_CHART: "\ue938",
  GRAPHIC_CHART_LINEAR: "\ue95b",
  GRAPHIC_CHECKMARK: "\ue937",
  GRAPHIC_CLICK: "\ue939",
  GRAPHIC_CLICK_LINEAR: "\ue95a",
  GRAPHIC_INSTALL: "\ue936",
  GRAPHIC_INSTALL_LINEAR: "\ue95c",
  GRAPHIC_WALLET: "\ue93a",
  GRAPHIC_WALLET_LINEAR: "\ue959",
  GRID: "\ue922",
  HEART: "\ue964",
  HOME: "\ue949",
  IMAGE_ALT: "\ue948",
  IMAGE: "\ue947",
  INFO_SOLID: "\ue93c",
  INFO: "\ue912",
  IOS: "\ue926",
  LIST: "\ue920",
  LOCK: "\ue953",
  LOCK_SOLID: "\ue956",
  LOCK_OPEN: "\ue95d",
  LOCK_SOLID_OPEN: "\ue960",
  LOGO: "\ue908",
  MORE: "\ue950",
  NEW_WINDOW: "\ue962",
  NEW_WINDOW_ADOPS: "\ue951",
  NOTE: "\ue933",
  NOTE_SOLID: "\ue95e",
  NOTE_ALT: "\ue95f",
  NOTIF_BELL: "\ue909",
  PAUSE_SOLID: "\ue935",
  PAUSE: "\ue934",
  PENCIL: "\ue90a",
  PHONE_NAV: "\ue94b",
  PHONE: "\ue90b",
  PLAY_SOLID: "\ue966",
  PLUS: "\ue911",
  PLUS_LARGE: "\ue969",
  POWER_OFF: "\ue958",
  QMARK_CIRCLE: "\ue92f",
  QMARK_SOLID: "\ue930",
  QMARK: "\ue931",
  REFRESH: "\ue90c",
  ROCKET: "\ue90d",
  SEARCH: "\ue940",
  SEARCH_ALT: "\ue93f",
  SHARE: "\ue91d",
  SHARE_ALT: "\ue91f",
  SETTINGS_SOLID: "\ue94a",
  SETTINGS: "\ue90e",
  STAR: "\ue96c",
  SYNC: "\ue90f",
  TABLE: "\ue921",
  TEXT: "\ue946",
  THUMBTACK: "\ue96a",
  THUMBTACK_SOLID: "\ue96b",
  TRE: "\ue967",
  UPLOAD: "\ue93e",
  VIDEO: "\ue954",
  VIDEO_PLAY: "\ue952",
  WRENCH: "\ue923",
} as const;

export const ICONS = Object.keys(icons) as Array<keyof typeof icons>;
export type IconTypes = typeof ICONS[number];
type IconChar = typeof icons[keyof typeof icons];

// TODO(stefan): Deprecate `icon` and use `type` instead.
// Icon can take an icon or type, but not both
export type IconBaseProps = {
  dataTitle?: string;
  className?: string;
} & (
  | {
      icon: IconChar;
      type?: never;
    }
  | {
      type: IconTypes;
      icon?: never;
    }
);

export type IconProps = IconBaseProps & React.HTMLAttributes<HTMLDivElement>;

export const parseIconProps = (props: IconBaseProps): IconTypes | undefined => {
  if (props.icon) {
    const type = Object.entries(icons).find(
      ([, icon]) => icon === props.icon
    )?.[0] as IconTypes;
    return type;
  }
  return props.type;
};

export const Icon = ({
  className,
  dataTitle,
  ...props
}: IconProps): JSX.Element | null => {
  const type = parseIconProps(props);
  if (!type) return null;
  return (
    <div
      {...props}
      data-title={dataTitle}
      className={classNames("icomoon", "icon", className, {
        [`icon-${type}`]: type,
      })}
    >
      {icons[type]}
    </div>
  );
};

// TODO(stefan): Deprecate this for typescript types
export const ICON_PROP_TYPE = PropTypes.oneOf(Object.values(icons));
